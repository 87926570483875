/* eslint-disable default-case */
import React from 'react';
import * as Api from '../services/api/common-api.js'
import DidoDrawer from '../components/Drawer.js'
import {GreenButton} from '../components/GreenButton.js'
import { withSnackbar } from 'notistack';
import { connect } from "react-redux";
import actions from "../actions.js";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Grid, Paper, styled, Typography } from '@mui/material';
import Markdown from 'react-markdown'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { SNACK_SUCCESS } from './../constants/common';
import rehypeRaw from 'rehype-raw'

const ActiveTile = styled(Paper)(({ theme }) => ({    
    height: '9vh',
    verticalAlign: 'middle',
    background: '#fff',
    width:'100%',
    overflow: 'clip', 
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    alignContent: 'center',    
    border: '1px solid #ccc',
    '&:hover': {
        background: '#ccc'
    },
    marginTop: '0.5vw',
}));

const REQUESTING_DATA_MSG_FROM_PYTHON = 'Requesting data...'
const END_OF_TEXT = 'END_OF_TEXT'

class AssistantPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { tiles: [], dialogOpen: false, loading : false, assistantMessage: null, isRunCancelled: false, streamStarted: false};
        this.myRef = React.createRef()
    }

    componentDidMount() {
        Api.getTiles().then(res => {
            this.setState({ tiles: res.data});
        })
    }

    executeScroll = () => (this.myRef && this.myRef.current) ? this.myRef.current.scrollIntoView({  block: "end", inline: "nearest", behaviour: "smooth"}) : ''

    onProgress = (p) => {
        const needStop = this.state.loading && p.currentTarget.responseText.includes(END_OF_TEXT)
        if (needStop) {
            this.setState({ loading: false });
        }

        this.setState({assistantMessage:p.currentTarget.responseText, streamStarted:!p.currentTarget.responseText.includes(END_OF_TEXT)});
        this.executeScroll();
    }    

    handleTileClick = async (tile) => {
        await this.setState({assistantMessage : 'Analyzing question...\r\n', loading: true });
        this.forceUpdate();
        const teamName = this.props.currentUser?.Teams.find(t => t.Id == this.props.currentTeam)?.Name;
        Api.sendTileMessageToFlowise({
            ClickedTileGuid: tile.Id,
            TeamName: teamName
        }, this.onProgress)
        .finally(() => this.setState({loading: false}))
       .catch(err => { if (this.state.isRunCancelled) Api.logger.errorWithSnackbar(`Error while assistant requesting, please contact support`, this, err)})
    }

    handleClipboardClick = async () => {
        navigator.clipboard.writeText(this.state.assistantMessage).then(() => {
            this.props.enqueueSnackbar("Text has been copied to the clipboard", SNACK_SUCCESS);
        });
    }

    render() {
        var buttonStyle = {};
        if (this.state.loading) {
            buttonStyle = {
                pointerEvents: 'none',
                background: '#ccc'
            }
        }
        
        let message = this.state.assistantMessage ?
            !this.state.assistantMessage.includes(END_OF_TEXT) ?
                this.state.assistantMessage + ' <span style="animation: blinker .75s linear infinite; font-size: 24px; color:#ddd">' + String.fromCharCode(9632) + '</span>' :
                this.state.assistantMessage.replace(END_OF_TEXT, '') : ''

        if (this.state.assistantMessage?.length > 20) {
            message = message.replace('Requesting data...','')
        }
        
            return (
            <div style={{ transform: 'translateZ(0px)', flexGrow: 1, }} >
                <DidoDrawer style={{ zIndex: 2000 }} pageTitle='Team assistent' />
                <Grid container style={{ marginTop: '75px', marginLeft: '40px', width: '95vw' }}>
                    <Grid item xs={3} sx={{ overflowY: 'auto', maxHeight: '90vh', marginTop: '0.5vw'}}>
                        {
                            this.state.tiles.map(tile => {
                                return (
                                    <ActiveTile onClick={() => this.handleTileClick(tile)} elevation={1} style={buttonStyle}>
                                        <Grid container>
                                            <Grid item xs={2} sx={{ textAlign: 'center', verticalAlign: 'middle', alignContent: 'center' }}>
                                                {tile.Avatar ?
                                                    <img src={tile.Avatar} width={40} height={40} alt="" style={{ marginTop: '0.5vh' }} /> : // sx={{ width: 32, height: 32 }} 
                                                    <AutoFixHighIcon sx={{ width: 24, height: 24 }} />
                                                }
                                            </Grid>
                                            <Grid item xs={10} alignContent={'center'} >
                                                <Typography>{tile.Name}</Typography></Grid>
                                        </Grid>
                                    </ActiveTile>
                                );
                            })
                        }
                    </Grid>
                    <Grid item xs={9}>
                        <Paper sx={{ overflowY: 'auto', height: '85vh', padding: '1vw', margin: '1vw', border: '1px solid #ccc' }}>
                            {
                                this.state.assistantMessage ?
                                        <Grid container >
                                            <Grid item xs={10}></Grid>
                                            <Grid item xs={2}>
                                                <GreenButton 
                                                startIcon={<ContentCopyIcon/>} 
                                                sx={{width: 'fit-content'}}
                                                onClick={this.handleClipboardClick}
                                                >
                                                    Copy to clipboard</GreenButton>
                                                    </Grid>
                                            <Grid item xs={10}>
                                                <Markdown 
                                                rehypePlugins={[rehypeRaw]}
                                                >{message}
                                                </Markdown>                                            
                                                
                                            </Grid>
                                            <Grid item xs={2}></Grid>
                                            <Grid item xs={10}></Grid>
                                            <Grid item xs={2}>
                                                {
                                                    this.state.assistantMessage?.includes(END_OF_TEXT) ?
                                                        <GreenButton
                                                            startIcon={<ContentCopyIcon />}
                                                            sx={{ width: 'fit-content' }}
                                                            onClick={this.handleClipboardClick}
                                                        >
                                                            Copy to clipboard</GreenButton>
                                                        :
                                                        <></>
                                                }
                                            </Grid>
                                            <Grid item xs={10}><div ref={this.myRef}></div ></Grid>
                                            <Grid item xs={2}></Grid>
                                        </Grid>
                                        :
                                        <div>Click on the left-side buttons to get messages from assistant</div>
                            }
                        </Paper>
                    </Grid>
                </Grid>               

            </div>);
    }
}

export default connect((state) => ({        
        currentOrg: state.didoReducer.currentOrg,
        currentTeam: state.didoReducer.currentTeam,
        currentUser: state.didoReducer.currentUser
    }), actions)(withSnackbar(AssistantPage));